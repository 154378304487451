import { useState, useEffect } from "react";

import styles from "./Game.module.scss";
import spin from "./../../images/spin.png";
import pointer from "./../../images/pointer.png";

//import bg from "./../../images/bg.png";
//import bg from "./../../images/bg_piqray.png";
//import bg from "./../../images/bg_tafmek.png";
//import bg from "./../../images/bg_tabrecta.png";
import bg from "./../../images/bg_kisqali.png";

//import wheel from "./../../images/tabrecta_wheel.png";
//import wheel from "./../../images/piqray_wheel.png";
import wheel from "./../../images/kisqali_wheel.png";
//import wheel from "./../../images/tafmek_wheel.png";

//import logo from "./../../images/tabrecta.png";
//import logo from "./../../images/piqray.png";
import logo from "./../../images/kisqali.png";
//import logo from "./../../images/tafmek.png";

export default function Game() {
  const [stage, setStage] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(120);
  const [tick, setTick] = useState(true);
  const [innerVal, setInnerVal] = useState(-1);
  const [outerVal, setOuterVal] = useState(-1);
  const [promoVal, setPromoVal] = useState(-1);

  const [innerRotation, setInnerRotation] = useState(0);
  const [outerRotation, setOuterRotation] = useState(0);
  const [spinCount, setSpinCount] = useState(1);

  useEffect(() => {
    if (timeRemaining === 0) {
      setStage(0);
      setTimeRemaining(120);
    }
  }, [timeRemaining]);

  // TAFMEK
  /*const innerValues = [
    ["Low Tumor Burden Patient", 0],
    ["Adjuvant Stage IIIC Patient", 270],
    ["Adjuvant Stage IIIB Patient", 180],
    ["Patient with Brain Metastasis", 90],
  ];
  const outerValues = [
    ["Disease recurrence", 36],
    ["Treatment tolerability", 108],
    ["Convenience of dosing regimen", 180],
    ["Long term adverse event", 252],
    ["Afraid that diagnosis will prevent active lifestyle", 324],
  ];
  const promoValues = [
    "TAF+MEK HCP CVA",
    "TAF+MEK COMBI-MB Leave Behind",
    "TAF+MEK Targeted Truths Brochure",
  ];*/

  // KISQALI
  const innerValues = [
    ["1L Postmenopausal woman, where AI is appropriate", 0],
    [
      "59-yo postmenopausal patient, relapsed 4 years after completing AI therapy",
      240,
    ],
    [
      "65-yo postmenopausal patient, relapsed 2 years after starting AI therapy",
      120,
    ],
  ];
  const outerValues = [
    ["Living longer in their #1 treatment goal", 36],
    ["Prefer to continue current lifestyle as much as possible", 108],
    ["Delay disease progression as long as possible", 180],
    ["Wants as much time as possible with family", 252],
    ["Avid traveler - wants to complete travel bucket list", 324],
  ];
  const promoValues = [
    "KISQALI M2/M3/M7 OS CVA",
    "CDK4/6i Trial Flashcard",
  ];

  // PIQRAY
  /*const innerValues = [
    [
      "62-yo post menopausal women facing a diagnosis of HR+/ Her2- abc, tested positive for PIK3CA mutation. She was prescribed palbociclib + letrozole. 12 months later, imaging confirmed new sites of metastasis to the liver.",
      0,
    ],
    [
      "68-yo post menopausal women facing a diagnosis of HR+/ Her2- abc. She was prescribed a CDK4/6 inhibitor + AI. After 28 months she reported back pain to her doctor. Bone scan confirmed new bone metastases.  NGS confirmed PIK3CA mutation positive.",
      240,
    ],
    [
      "60-yo post menopausal women facing a diagnosis of HR+/ Her2- abc, PIK3CA +. She was prescribed CDK4/6 + AI. However, just one year later, imaging confirmed new sites of metastasis to the lung and liver. Patient has history of well controlled diabetes type II; HbAIC 6.4.",
      120,
    ],
  ];
  const outerValues = [
    [
      "HR+ Her2- abc patients with a PIK3CA mutation face a poor prognosis, and can have a shorter mPFS & endocrine resistance.",
      36,
    ],
    [
      "Patients facing a diagnosis of HR+/ Her2- abc may have less time progression free.",
      108,
    ],
    [
      "Patients with a PIK3CA mutation often have a worse prognosis, and may be concerned about finding a therapy that's specifically for patients like her.",
      180,
    ],
    ["Patients and Providers may be concerned about management of adverse events.", 252],
    [
      "Patients may be concerned that they will have to receive chemotherapy.",
      324,
    ],
  ];
  const promoValues = [
    "PIQRAY Core Visual Aid Digital",
    "Managing Patients on PIQRAY Digital Brochure",
  ];*/

  // TABRECTA
  /*const innerValues = [
    ["70-yo male, former smoker, newly diagnosed with mNSCLC", 0],
    ["65-yo female, newly diagnosed mNSCLC, non-smoker with brain metastases", 270],
    ["74-yo male, non-smoker, with recurrent mNSCLC and prior chemo", 180],
    [
      "71-yo female, former smoker, with recurrent mNSCLC & prior IO/chemo combo",
      90,
    ],
  ];
  const outerValues = [
    ["HCP tests PD-L1, EGFR & ALK upfront & considers CGP upon progression", 0],
    ["HCP acts solely on PD-L1 status, especially when it's >50%", 60],
    ["Efficacy of Tabrecta & Tepmetko are the same", 120],
    [
      "Patient/family want to start therapy now and not wait for CGP results",
      180,
    ],
    ["Tepmetko has convenient QD dosing", 240],
    [
      "METex14 has such low prevalence that HCP is unlikely to find such patients in their practice",
      300,
    ],
  ];
  const promoValues = [
    "Portfolio CVA",
    "NCCN Leave Behind",
    "Portfolio Leave Behind",
  ];*/

  useEffect(() => {
    console.log(innerValues, outerValues);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTick(!tick);
      if (stage === 2) setTimeRemaining((prev) => prev - 1);
    }, 1000);
  }, [tick]);

  const secondsToMinutes = (input) => {
    input = Math.min(input, 120);
    let minutes = Math.floor(input / 60);
    let seconds = input - minutes * 60;

    if (seconds.toString().length === 1) seconds = "0" + seconds;

    return minutes + ":" + seconds; // add zeros
  };

  const randomNumber = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(0);
  };

  const randomize = () => {
    setStage(1);
    setTimeRemaining(121);

    const [randOuter, randInner, randPromo] = [
      randomNumber(0, outerValues.length - 1),
      randomNumber(0, innerValues.length - 1),
      randomNumber(0, promoValues.length - 1),
    ];

    console.log(randOuter, randInner, randPromo);

    setOuterVal(randOuter);
    setInnerVal(randInner);
    setPromoVal(randPromo);

    const baseline = spinCount * 720;

    setInnerRotation(baseline + innerValues[randInner][1]);
    setOuterRotation(baseline + outerValues[randOuter][1]);
    setSpinCount(spinCount + 1);

    setTimeout(() => {
      setStage(2);
    }, 5000);
  };

  return (
    <div className={styles.game}>
      <img src={bg} />
      <div className={styles.screen}>
        <div className={styles.countdown}>
          <h2>Time Remaining</h2>
          <h3 className={timeRemaining > 30 ? styles.inTheGood : ""}>
            {secondsToMinutes(timeRemaining)}
          </h3>
        </div>
      </div>
      <div className={styles.windows}>
        <div>{stage === 2 && innerValues[innerVal][0]}</div>
        <div>{stage === 2 && outerValues[outerVal][0]}</div>
        <div>
          <ol>
          {/*stage === 2 && promoValues[promoVal]*/}
          {promoValues.map((v) => {
            return <li>{v}</li>;
          })}
          </ol>
          {/*promoValues.join(", ")*/}
        </div>
      </div>
      <div
        onClick={() => {
          if (stage === 0 || stage === 2) {
            randomize();
          }
        }}
        className={[
          styles.wheelContainer,
          stage === 0 || stage === 2 ? styles.clickable : "",
        ].join(" ")}
      >
        <img
          src={wheel}
          className={styles.outerWheel}
          style={{
            transform: `rotate(${outerRotation}deg)`,
          }}
        />
        <img
          src={wheel}
          className={styles.innerWheel}
          style={{
            transform: `rotate(${-innerRotation}deg)`,
          }}
        />
        <img src={spin} className={styles.spin} />
        <img src={pointer} className={styles.pointer} />
      </div>
      <div className={styles.smallLogo}>
        <img src={logo} />
      </div>
    </div>
  );
}
